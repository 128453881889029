import { useCallback, useMemo, useState } from 'react';
import { CopyIcon } from '@chakra-ui/icons';
import { Box, Button, Divider, Grid, GridItem, Heading, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';

import RebalancingContractsTable from 'components/tables/RebalancingContractsTable';
import useThemedToast from 'hooks/useThemedToast';
import BarGraph from 'pages/qis/rebalancing/GraphBar';
import LineGraph from 'pages/qis/rebalancing/GraphLine';
import {
	RebalancingType,
	useApplyContractRebalancingMutation,
	useGetTransfertContractsToRebalanceQuery,
	useRecomputeRebalancingMutation,
} from 'services/rebalancing-contrat';
import { ContractRebalancing, ContractWithTransfer } from 'types/invest-deal.type';
import { PortfolioType } from 'types/investmentPreferences.type';

const RebalancingTransfert = () => {
	const toast = useThemedToast();

	const { data, isFetching } = useGetTransfertContractsToRebalanceQuery();
	const [reCompute, { isLoading: isRecomputeLoading, reset }] = useRecomputeRebalancingMutation();
	const [applyRebalancing, { isLoading: isRebalancingLoading }] = useApplyContractRebalancingMutation();

	const [selectedContract, setSelectedContract] = useState<ContractWithTransfer>();
	const [newPortfolioType, setNewPortfolioType] = useState<PortfolioType>();
	const [portfolioChoice, setPortfolioChoice] = useState<PortfolioType>();

	const rebalancingData = useMemo(() => {
		if (!selectedContract) return null;

		return selectedContract.rebalancingData[RebalancingType.GLOBAL_REBALANCE];
	}, [selectedContract]);

	const handleRecompute = useCallback(
		(contract: ContractRebalancing, saveRecompute: boolean) => {
			reCompute({ contractId: contract.id, saveRecompute, newPortfolioType })
				.unwrap()
				.then((r) => {
					setSelectedContract((prev) => ({ ...prev!, rebalancingData: r }));
					toast({ status: 'success', title: 'Succès', description: 'Recompute done' });
				})
				.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
		},
		[newPortfolioType, reCompute, toast],
	);

	const handleApplyRebalancing = useCallback(
		(contract: ContractRebalancing) => {
			applyRebalancing({ contractId: contract.id, rebalanceType: RebalancingType.GLOBAL_REBALANCE, newPortfolioType })
				.unwrap()
				.then(() => {
					setSelectedContract(undefined);
					setPortfolioChoice(undefined);
					setNewPortfolioType(undefined);
					toast({ status: 'success', title: 'Succès', description: 'Rebalancing applied' });
				})
				.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
		},
		[applyRebalancing, newPortfolioType, toast],
	);

	return (
		<VStack w="100%" p="32px" align="start" bg="white" spacing="32px">
			<Heading size="lg">Arbitrage Transfert</Heading>

			<Divider />

			<VStack w="100%" align="start" spacing="16px">
				<Heading as="h4" size="md">
					Transfer Contracts à Arbitrer
				</Heading>

				<Skeleton isLoaded={!isFetching} w="100%">
					<RebalancingContractsTable
						isTransfer
						contracts={data ?? []}
						pageSize={10}
						selectedContract={selectedContract}
						onClick={(contract) => {
							setSelectedContract(contract);
							if (contract.investmentPreferences.portfolioType)
								setPortfolioChoice(contract.investmentPreferences.portfolioType);
							reset();
						}}
					/>
				</Skeleton>
			</VStack>

			{selectedContract && rebalancingData && (
				<>
					<Divider />

					<VStack w="100%" align="start" spacing="16px">
						<Heading as="h4" size="md">
							Détails du Contrat - {selectedContract.user.email}
						</Heading>

						<HStack w="100%" justify="space-between" alignItems="start" padding="10px" gap="24px">
							{selectedContract.transfer?.opsProperties && (
								<Box w="100%">
									<VStack w="100%" align="start" spacing="4">
										<Text fontWeight="bold">
											Assigned to {selectedContract.transfer?.opsProperties.assignedOpsEmail}
										</Text>

										{selectedContract.transfer?.opsProperties.comments.length > 0 ? (
											<VStack w="100%" align="start" spacing="2">
												<Text fontWeight="bold">Comments:</Text>
												{selectedContract.transfer?.opsProperties.comments.map((comment) => (
													<Box key={comment.id} p="3" w="100%" borderLeft="4px" borderColor="gray.200" bg="gray.50">
														<Text fontSize="sm" color="gray.600" mb="1">
															{comment.authorEmail} • {new Date(comment.updatedAt).toLocaleString()}
														</Text>
														<Text>{comment.content}</Text>
													</Box>
												))}
											</VStack>
										) : (
											<Text>No comments</Text>
										)}
									</VStack>
								</Box>
							)}

							<Box w="100%">
								<VStack w="100%" align="start" spacing="4">
									<Text fontWeight="bold">Type du portefeuille:</Text>
									<HStack w="100%">
										{Object.values(PortfolioType)
											.filter((type) => type !== PortfolioType.CUSTOM)
											.map((type) => (
												<Button
													key={type}
													colorScheme={portfolioChoice === type ? 'blue' : 'gray'}
													onClick={() => setPortfolioChoice(type)}
													isDisabled={type === PortfolioType.TITANIUM}
													title={
														type === PortfolioType.TITANIUM
															? "L'arbitrage de trasnfer vers Titanium n'a pas encore été dev"
															: undefined
													}
												>
													{type}
												</Button>
											))}
									</HStack>
									<Button
										colorScheme="red"
										isDisabled={portfolioChoice === selectedContract.investmentPreferences.portfolioType}
										isLoading={isRecomputeLoading}
										onClick={() => {
											if (portfolioChoice) {
												setNewPortfolioType(portfolioChoice);
												handleRecompute(selectedContract, false);
											}
										}}
									>
										Update type
									</Button>
								</VStack>
							</Box>
						</HStack>

						<Divider />

						<HStack w="100%" justify="space-between">
							<Heading as="h4" size="md" id="visualisation">
								Visualisation
							</Heading>
							<Text>
								Last refresh date: <b>{new Date(rebalancingData.last_refresh_date).toLocaleDateString('fr-FR')}</b>
							</Text>
						</HStack>

						<Grid templateRows="repeat(4, 1fr)" templateColumns="repeat(10, 1fr)" columnGap="30px" w="100%">
							<GridItem colSpan={5} rowSpan={2}>
								<BarGraph
									data={rebalancingData.turnover_asset_category_view_dict}
									turnover={rebalancingData.turnover_asset_category_view_euro}
									aum={+selectedContract.amount!}
									category="Asset Category"
									title="Asset Category View"
								/>
							</GridItem>
							<GridItem colSpan={5} rowSpan={2}>
								<BarGraph
									data={rebalancingData.turnover_asset_class_view_dict}
									turnover={rebalancingData.turnover_asset_class_view_euro}
									aum={+selectedContract.amount!}
									category="Asset Class"
									title="Asset Class View"
								/>
							</GridItem>
							<GridItem colSpan={6} rowSpan={2}>
								<BarGraph
									data={rebalancingData.turnover_basic_view_dict}
									turnover={rebalancingData.turnover_basic_view_euro}
									aum={+selectedContract.amount!}
									category=""
									title="Basic View"
									height="340px"
								/>
							</GridItem>
							<GridItem colSpan={4} rowSpan={2}>
								<LineGraph
									data={{
										actual: rebalancingData.actual_volatility_and_return_tuple,
										ideal: rebalancingData.ideal_volatility_and_return_tuple,
										new: rebalancingData.new_volatility_and_return_tuple,
									}}
									isOptimal={rebalancingData.distance_is_optimized}
									reachableTemperature={rebalancingData.reachable_temperature}
									temperature={selectedContract.investmentPreferences.temperature}
									isEsg={selectedContract.investmentPreferences.esg}
								/>
							</GridItem>
						</Grid>

						<HStack w="100%" align="start">
							<Box borderWidth="2px" borderRadius="4px" p="16px" w="50%">
								<VStack align="start">
									<HStack w="100%" justify="space-between">
										<Heading as="h4" size="md">
											arbitrage_percentage_buy_dict
										</Heading>
										<Button
											onClick={() => {
												navigator.clipboard.writeText(
													JSON.stringify(rebalancingData.arbitrage_percentage_buy_dict, undefined, 4),
												);
												toast({ title: 'Copié', status: 'success', duration: 2000 });
											}}
										>
											<CopyIcon />
										</Button>
									</HStack>
									<Text as="pre">{JSON.stringify(rebalancingData.arbitrage_percentage_buy_dict, undefined, 4)}</Text>
								</VStack>
							</Box>

							<Box borderWidth="2px" borderRadius="4px" p="16px" w="50%">
								<VStack align="start">
									<HStack w="100%" justify="space-between">
										<Heading as="h4" size="md">
											arbitrage_percentage_sell_dict
										</Heading>
										<Button
											onClick={() => {
												navigator.clipboard.writeText(
													JSON.stringify(rebalancingData.arbitrage_percentage_sell_dict, undefined, 4),
												);
												toast({ title: 'Copié', status: 'success', duration: 2000 });
											}}
										>
											<CopyIcon />
										</Button>
									</HStack>
									<Text as="pre">{JSON.stringify(rebalancingData.arbitrage_percentage_sell_dict, undefined, 4)}</Text>
								</VStack>
							</Box>
						</HStack>
					</VStack>

					<HStack w="100%" justify="space-between">
						<HStack>
							<Button isLoading={isRecomputeLoading} onClick={() => handleRecompute(selectedContract, false)}>
								Visualize rebalancing
							</Button>
							<Button isLoading={isRecomputeLoading} onClick={() => handleRecompute(selectedContract, true)}>
								Recompute rebalancing
							</Button>
						</HStack>
						<Button
							colorScheme="red"
							isLoading={isRebalancingLoading}
							onClick={() => handleApplyRebalancing(selectedContract)}
							isDisabled={!rebalancingData.distance_is_optimized || newPortfolioType === PortfolioType.ELITE}
						>
							{selectedContract.transfer?.isElite ? 'Envoi arbitrage APICIL' : 'Apply rebalancing'}
						</Button>
					</HStack>
				</>
			)}
		</VStack>
	);
};

export default RebalancingTransfert;
