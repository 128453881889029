import { dashboardApi } from 'store/api';
import { ContractRebalancing } from 'types/invest-deal.type';
import { PortfolioType } from 'types/investmentPreferences.type';

export type LabHoldings = Record<string, number>;
export type LabTurnover = Record<string, number>;

export enum RebalancingType {
	GLOBAL_REBALANCE = 'global_rebalance',
	SCPI_REBALANCE = 'scpi_rebalance',
	CUSTOM_REBALANCE = 'custom_rebalance',
}

interface LabArbitrageRebalance {
	actual_portfolio: LabHoldings; // unused in front
	ideal_portfolio: LabHoldings; // unused in front
	targeted_portfolio: LabHoldings; // to send to apicil
	turnover_portfolio: number; // filter in back
	turnover_portfolio_euro: number; // unused in front
	custom: boolean; // filter in back
	rebalance_decision: boolean; // filter in back
	last_refresh_date: string; // display
	distance_is_optimized: boolean; // display
	reachable_temperature: number | null; // display
	optimal: boolean; // display
	euro_buy_dict: LabHoldings; // mail nico
	euro_sell_dict: LabHoldings; // mail nico
	actual_volatility_and_return_tuple: number[]; // line graph
	ideal_volatility_and_return_tuple: number[]; // line graph
	new_volatility_and_return_tuple: number[]; // line graph
	turnover_basic_view_dict: LabTurnover; // bar graph
	turnover_asset_class_view_dict: LabTurnover; // bar graph
	turnover_asset_category_view_dict: LabTurnover; // bar graph
	turnover_basic_view_euro: number; // bar graph
	turnover_asset_class_view_euro: number; // bar graph
	turnover_asset_category_view_euro: number; // bar graph
	arbitrage_percentage_buy_dict: LabHoldings; // debug for aymane
	arbitrage_percentage_sell_dict: LabHoldings; // debug for aymane
}

export interface LabArbitrageTableResponse {
	[RebalancingType.GLOBAL_REBALANCE]: LabArbitrageRebalance & { rebalance_type: RebalancingType.GLOBAL_REBALANCE };
	[RebalancingType.SCPI_REBALANCE]: LabArbitrageRebalance & { rebalance_type: RebalancingType.SCPI_REBALANCE };
}

export type TriggerArbitrageResponse = {
	arbitrageId?: number | undefined;
	requiredSignature: boolean;
};

type ContractIdDto = {
	contractId: string;
	newPortfolioType?: PortfolioType;
	saveRecompute?: boolean;
};

export type CustomArbitrageBody = {
	rebalance_turnover: number;
	assets_to_sell_totally: string[];
	assets_to_sell_potentially: string[];
	assets_to_buy_imperatively: string[];
	minimal_weight_for_asset_to_buy_imperatively: number;
};

export type RepartitionAsPercentage = {
	codeIsinSupport: string;
	pourcentage: number;
};

export type CustomArbitrageDto =
	| {
			rebalanceType: Exclude<RebalancingType, RebalancingType.CUSTOM_REBALANCE>;
	  }
	| (CustomArbitrageBody & {
			rebalanceType: RebalancingType.CUSTOM_REBALANCE;
	  });

export type FiltersContractsRebalancing = {
	min: number;
	max: number;
	minRisk: number;
	maxRisk: number;
	rebalanceType: RebalancingType;
	green?: boolean;
	portfolioTypes: PortfolioType[];
	holdings?: string[];
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getContractsToRebalance: builder.query<ContractRebalancing[], FiltersContractsRebalancing>({
			query: (data) => ({
				url: '/bo/qis/contracts-to-rebalance',
				params: data,
				method: 'GET',
			}),
			providesTags: ['CONTRACT_REBALANCE'],
		}),
		getTransfertContractsToRebalance: builder.query<ContractRebalancing[], void>({
			query: () => '/bo/qis/transfert-contracts-to-rebalance',
			providesTags: ['CONTRACT_REBALANCE'],
		}),
		recomputeRebalancing: builder.mutation<LabArbitrageTableResponse, ContractIdDto>({
			query: (data) => ({
				url: '/bo/qis/compute-rebalancing',
				params: data,
				method: 'PUT',
			}),
			invalidatesTags: ['CONTRACT_REBALANCE'],
		}),
		applyContractRebalancing: builder.mutation<TriggerArbitrageResponse, ContractIdDto & CustomArbitrageDto>({
			query: (data) => ({
				url: '/bo/qis/apply-rebalancing',
				body: data,
				method: 'POST',
			}),
			invalidatesTags: ['CONTRACT_REBALANCE'],
		}),
		sendSignRebalancing: builder.mutation<void, ContractIdDto & { arbitrageId: number } & CustomArbitrageDto>({
			query: (data) => ({
				url: '/bo/qis/send-sign-rebalancing',
				body: data,
				method: 'POST',
			}),
			invalidatesTags: ['CONTRACT_REBALANCE'],
		}),
		rebalanceBatch: builder.mutation<void, { contractIds: string[]; sendSignatures: boolean } & CustomArbitrageDto>({
			query: (data) => ({
				url: '/bo/qis/apply-batch-rebalancing',
				body: data,
				method: 'POST',
			}),
			invalidatesTags: ['CONTRACT_REBALANCE'],
		}),
		cancelRebalancing: builder.mutation<void, ContractIdDto>({
			query: (data) => ({
				url: '/bo/qis/cancel-rebalancing',
				params: data,
				method: 'DELETE',
			}),
			invalidatesTags: ['CONTRACT_REBALANCE'],
		}),
		previewCustomRebalance: builder.query<LabArbitrageRebalance, ContractIdDto & CustomArbitrageBody>({
			query: (data) => ({
				url: '/bo/qis/preview-custom-rebalancing',
				params: data,
				method: 'POST',
			}),
			providesTags: ['CONTRACT_REBALANCE'],
		}),
	}),
});

export const {
	useGetContractsToRebalanceQuery,
	useGetTransfertContractsToRebalanceQuery,
	useRecomputeRebalancingMutation,
	useApplyContractRebalancingMutation,
	useSendSignRebalancingMutation,
	useCancelRebalancingMutation,
	useRebalanceBatchMutation,
	usePreviewCustomRebalanceQuery,
} = endpoints;
