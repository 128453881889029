import { SearchBy } from 'components/filters/SearchBy';
import useSessionStorageState from 'hooks/useSessionStorate';
import { AllDeal } from 'services/deal';
import { BOContext, LegalEntity, ProductType, SubscriptionStatus } from 'types/global.type';
import { EnvelopeType } from 'types/invest-deal.type';
import { SavingsAccountType } from 'types/savingsAccount.type';

export enum DealProductOptions {
	AV = 'AV',
	AV_LUX = 'AV_LUX',
	PER = 'PER',
	SP = 'SP',
	PE = 'PE',
	SCPI = 'SCPI',
	CER = 'CER',
	CATPM = 'CATPM',
	CATPP = 'CATPP',
	ART = 'ART',
	CRYPTO = 'CRYPTO',
	CROWDFUNDING = 'CROWDFUNDING',
	GIRARDIN = 'GIRARDIN',
	TRANSFER = 'TRANSFER',
}

export const filterDealproductType = (s: AllDeal, productFilter: DealProductOptions[]) => {
	let selectedproductFilter: DealProductOptions | undefined = undefined;

	if (s.productType === ProductType.CASH) {
		if (s.type === SavingsAccountType.CER) {
			selectedproductFilter = DealProductOptions.CER;
		} else if (s.type === SavingsAccountType.CAT) {
			selectedproductFilter = s.legalEntity === LegalEntity.MORAL ? DealProductOptions.CATPM : DealProductOptions.CATPP;
		}
	} else if (s.productType === ProductType.INVEST) {
		if (s.dealType === 'SUBSCRIPTION') {
			if (s.structuredProducts.length > 0) {
				selectedproductFilter = DealProductOptions.SP;
			} else if (s.envelope.type === EnvelopeType.AV || s.envelope.type === EnvelopeType.AV_HYBRIDE) {
				selectedproductFilter = DealProductOptions.AV;
			} else if (s.envelope.type === EnvelopeType.PER) {
				selectedproductFilter = DealProductOptions.PER;
			}
		} else if (s.dealType === 'TRANSFER') {
			selectedproductFilter = DealProductOptions.TRANSFER;
		}
	} else if (s.productType === ProductType.CROWDFUNDING) selectedproductFilter = DealProductOptions.CROWDFUNDING;
	else if (s.productType === ProductType.ART) selectedproductFilter = DealProductOptions.ART;
	else if (s.productType === ProductType.GIRARDIN) selectedproductFilter = DealProductOptions.GIRARDIN;
	else if (s.productType === ProductType.SCPI) selectedproductFilter = DealProductOptions.SCPI;
	else if (s.productType === ProductType.PE) selectedproductFilter = DealProductOptions.PE;
	else if (s.productType === ProductType.CRYPTO) selectedproductFilter = DealProductOptions.CRYPTO;
	else if (s.productType === ProductType.AV_LUX) selectedproductFilter = DealProductOptions.AV_LUX;

	return productFilter.includes(selectedproductFilter!);
};

const initialState = {
	partnerFilter: [],
	statusFilter: Object.values(SubscriptionStatus).filter((s) => s !== SubscriptionStatus.PARTNER_TREATMENT),
	clientTypeFilter: [],
	opsFilter: [],
	onlyOverdue: false,
	onlyCallToDo: false,
	includeDealsWithBI: true,
	productFilter: Object.values(DealProductOptions),
	input: '',
	searchBy: 'email' as const,
};
const useDealFilters = (context: BOContext, customSearchBy?: SearchBy, customInput?: string) => {
	const [input, setInput] = useSessionStorageState(`${context}_DEAL_SEARCH_INPUT`, customInput ?? initialState.input);
	const [searchBy, setSearchBy] = useSessionStorageState<SearchBy>(
		`${context}_DEAL_SEARCH_BY`,
		customSearchBy ?? initialState.searchBy,
	);
	const [clientTypeFilter, setClientTypeFilter] = useSessionStorageState<string[]>(
		`${context}_DEAL_CLIENT_TYPE_FILTER`,
		initialState.clientTypeFilter,
	);
	const [includeDealsWithBI, setIncludeDealsWithBI] = useSessionStorageState<boolean>(
		`${context}_DEAL_INCLUDE_DEALS_WITH_BI`,
		initialState.includeDealsWithBI,
	);
	const [onlyCallToDo, setOnlyCallToDo] = useSessionStorageState<boolean>(
		`${context}_DEAL_ONLY_CALL_TO_DO`,
		initialState.onlyCallToDo,
	);
	const [onlyOverdue, setOnlyOverdue] = useSessionStorageState<boolean>(
		`${context}_DEAL_ONLY_OVERDUE_DEALS`,
		initialState.onlyOverdue,
	);
	const [statusFilter, setStatusFilter] = useSessionStorageState<SubscriptionStatus[]>(
		`${context}_DEAL_STATUS_FILTER`,
		initialState.statusFilter,
	);
	const [productFilter, setProductFilter] = useSessionStorageState<DealProductOptions[]>(
		`${context}_DEAL_PRODUCT_FILTER`,
		initialState.productFilter,
	);
	const [partnerFilter, setPartnerFilter] = useSessionStorageState<string[]>(
		`${context}_DEAL_PARTNER_FILTER`,
		initialState.partnerFilter,
	);
	const [opsFilter, setOpsFilter] = useSessionStorageState<string[]>(
		`${context}_DEAL_OPS_FILTER`,
		initialState.opsFilter,
	);

	const resetFilters = () => {
		setInput(initialState.input);
		setSearchBy(initialState.searchBy);
		setProductFilter(initialState.productFilter);
		setStatusFilter(initialState.statusFilter);
		setClientTypeFilter(initialState.clientTypeFilter);
		setPartnerFilter(initialState.partnerFilter);
		setOpsFilter(initialState.opsFilter);
		setOnlyOverdue(initialState.onlyOverdue);
		setOnlyCallToDo(initialState.onlyCallToDo);
		setIncludeDealsWithBI(initialState.includeDealsWithBI);
	};

	return {
		partnerFilter,
		setPartnerFilter,
		statusFilter,
		setStatusFilter,
		clientTypeFilter,
		setClientTypeFilter,
		opsFilter,
		setOpsFilter,
		onlyOverdue,
		setOnlyOverdue,
		onlyCallToDo,
		setOnlyCallToDo,
		includeDealsWithBI,
		setIncludeDealsWithBI,
		productFilter,
		setProductFilter,
		input,
		setInput,
		searchBy,
		setSearchBy,
		resetFilters,
	};
};

export default useDealFilters;
