import { OpsTransferDeal } from 'services/deal';
import { EditableOpsPropertiesFields } from 'services/ops/properties';
import { dashboardApi } from 'store/api';
import { TransferStatus } from 'types/perTransfer.type';

interface UpdateTransferDTO {
	id: string;
	isElite?: boolean;
	status?: TransferStatus;
	properties?: EditableOpsPropertiesFields;
}

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getTransfers: builder.query<OpsTransferDeal[], { searchBy: string; input: string | undefined }>({
			query: (queryParams) => ({
				url: '/bo/ops/transfers',
				params: queryParams,
				method: 'GET',
			}),
			providesTags: ['OPS_TRANSFERS'],
		}),
		getTransferToRebalance: builder.query<OpsTransferDeal, { userId: string }>({
			query: ({ userId }) => ({
				url: `/bo/ops/transfers/to-rebalance/${userId}`,
				method: 'GET',
			}),
			providesTags: ['OPS_TRANSFERS'],
		}),
		getTransferById: builder.query<OpsTransferDeal, { id: string }>({
			query: ({ id }) => ({
				url: `/bo/ops/transfers/${id}`,
				method: 'GET',
			}),
			providesTags: (res) => [
				{ type: 'OPS_TRANSFERS', id: res?.id },
				{ type: 'OPS_PROPERTIES', id: res?.opsPropertiesId },
			],
		}),
		updateTransfer: builder.mutation<OpsTransferDeal, UpdateTransferDTO>({
			query: ({ id, ...body }) => ({
				url: `/bo/ops/transfers/${id}`,
				body,
				method: 'PUT',
			}),
			invalidatesTags: (res) => [
				{ type: 'OPS_TRANSFERS', id: res?.id },
				{ type: 'OPS_PROPERTIES', id: res?.opsPropertiesId },
			],
		}),
		deleteTransfer: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `/bo/ops/transfers/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['OPS_TRANSFERS'],
		}),
	}),
});

export const {
	useGetTransfersQuery,
	useLazyGetTransferToRebalanceQuery,
	useLazyGetTransfersQuery,
	useGetTransferByIdQuery,
	useLazyGetTransferByIdQuery,
	useUpdateTransferMutation,
	useDeleteTransferMutation,
} = endpoints;
