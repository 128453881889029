import { useMemo, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	HStack,
	Input,
	Menu,
	MenuButton,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	Table,
	TableContainer,
	Tbody,
	Text,
	Thead,
	Tr,
} from '@chakra-ui/react';

import { BoolTd, DefaultTd } from 'components/tables/columns/Td';
import { DefaultTh } from 'components/tables/columns/Th';
import { ContractRebalancing, ContractWithTransfer } from 'types/invest-deal.type';
import { displayMoney, isNone } from 'utils/functions';

type ContractsTableProps =
	| {
			isTransfer: false;
			contracts: ContractRebalancing[];
			pageSize: number;
			selectedContract?: ContractRebalancing;
			onClick: (c: ContractRebalancing) => void;
	  }
	| {
			isTransfer: true;
			contracts: ContractWithTransfer[];
			pageSize: number;
			selectedContract?: ContractWithTransfer;
			onClick: (c: ContractWithTransfer) => void;
	  };

const RebalancingContractsTable = ({
	isTransfer,
	contracts,
	pageSize,
	selectedContract,
	onClick,
}: ContractsTableProps) => {
	const [pageIndex, setPageIndex] = useState<number>(0);
	const [searchBy, setSearchBy] = useState<string>('name');
	const [input, setInput] = useState<string>('');

	const filteredContracts: ContractWithTransfer[] | ContractRebalancing[] = useMemo(
		() =>
			contracts.filter((c) =>
				(searchBy === 'email' ? c?.user?.email ?? '' : c?.user?.kyc?.lastName ?? '')
					.toLowerCase()
					.includes(input.toLowerCase()),
			),
		[contracts, input, searchBy],
	);

	return (
		<Box>
			<HStack>
				<Input
					w="500px"
					placeholder={searchBy === 'name' ? 'Nom' : 'Email'}
					value={input}
					onChange={(v) => setInput(v.target.value)}
				/>
				<Menu closeOnSelect={false}>
					<MenuButton as={Button}>Chercher par</MenuButton>
					<MenuList minWidth="240px">
						<MenuOptionGroup value={searchBy} onChange={(v) => setSearchBy(v as string)} type="radio">
							<MenuItemOption value="name">Nom</MenuItemOption>
							<MenuItemOption value="email">Email</MenuItemOption>
						</MenuOptionGroup>
					</MenuList>
				</Menu>
			</HStack>

			<Box w="100%" textAlign="right" mb="16px" mt="-32px" fontWeight="semibold" userSelect="none">
				<Text as="span" color="gray.600">
					Show {pageIndex + 1} to {pageIndex + pageSize} of {filteredContracts.length} entries{' '}
				</Text>
				<ChevronLeftIcon
					mx="16px"
					boxSize="24px"
					bg="gray.100"
					cursor="pointer"
					onClick={() =>
						setPageIndex(pageIndex === 0 ? Math.floor(filteredContracts.length / pageSize) * 10 : pageIndex - pageSize)
					}
				/>
				<ChevronRightIcon
					boxSize="24px"
					bg="gray.100"
					cursor="pointer"
					onClick={() => setPageIndex(pageIndex + 1 * pageSize >= filteredContracts.length ? 0 : pageIndex + pageSize)}
				/>
			</Box>

			<TableContainer>
				<Table variant="simple" size="sm">
					<Thead>
						<Tr>
							<DefaultTh>Email</DefaultTh>
							<DefaultTh>Nom</DefaultTh>
							<DefaultTh>Prénom</DefaultTh>
							<DefaultTh>Amount</DefaultTh>
							<DefaultTh>Risk</DefaultTh>
							<DefaultTh>Portfolio</DefaultTh>
							<DefaultTh>Temp</DefaultTh>
							<DefaultTh>ESG</DefaultTh>
							<DefaultTh>Apicil ID</DefaultTh>
							<DefaultTh>To Elite</DefaultTh>
						</Tr>
					</Thead>

					<Tbody>
						{filteredContracts
							.slice(pageIndex, pageIndex + pageSize)
							.map((contract: ContractWithTransfer | ContractRebalancing) => (
								<Tr
									key={contract.id}
									onClick={() => onClick(contract)}
									cursor="pointer"
									bg={
										selectedContract?.id === contract.id
											? 'blue.50'
											: isTransfer &&
											  (!('transfer' in contract) || ('transfer' in contract && isNone(contract.transfer)))
											? 'red.50'
											: 'white'
									}
								>
									<DefaultTd>{contract.user?.email}</DefaultTd>
									<DefaultTd>{contract.user?.kyc?.lastName}</DefaultTd>
									<DefaultTd>{contract.user?.kyc?.firstName}</DefaultTd>
									<DefaultTd>{contract.amount ? displayMoney(contract.amount) : null}</DefaultTd>
									<DefaultTd>{contract.investmentPreferences.risk}</DefaultTd>
									<DefaultTd>{contract.investmentPreferences.portfolioType}</DefaultTd>
									<DefaultTd>{contract.investmentPreferences.temperature ?? 2.5}</DefaultTd>
									<BoolTd value={contract.investmentPreferences.esg} />
									<DefaultTd>{contract.externalProviderId}</DefaultTd>
									{isTransfer === true && 'transfer' in contract ? (
										<BoolTd value={contract.transfer?.isElite} />
									) : (
										<DefaultTd />
									)}
								</Tr>
							))}
					</Tbody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default RebalancingContractsTable;
